<script setup lang="ts">
import { computed } from 'vue';

import { UiCarousel } from '@/components/ui';
import { UiCarouselItemProps } from '@/components/ui/carousel/UiCarouselItem.vue';

const props = defineProps<{
  campaignList: { href: string; src: string; alt: string }[];
}>();

const itemsRef = computed(() =>
  props.campaignList
    ? props.campaignList.map((i): UiCarouselItemProps => {
        return {
          to: i.href,
          src: i.src,
          alt: i.alt,
          target: '_blank',
        };
      })
    : []
);
</script>
<template>
  <!-- NOTE: 開催中のキャンペーンが1つの場合はスライドしない -->
  <div v-if="campaignList?.length === 1" class="slider_inr">
    <ul class="slider_list">
      <li class="slider_item">
        <a :href="itemsRef[0].to" target="_blank" rel="noopener"
          ><img :src="itemsRef[0].src" :alt="itemsRef[0].alt" width="285" height="107"
        /></a>
      </li>
    </ul>
  </div>
  <div v-else class="slider_inr">
    <ul class="slider_list slick-initialized slick-slider">
      <div class="slide_prev slick-arrow"></div>
      <div class="slick-list draggable">
        <UiCarousel v-if="campaignList" :items="itemsRef" class="slick-track" />
      </div>
      <div class="slide_next slick-arrow"></div>
    </ul>
  </div>
</template>

<style scoped lang="scss">
@use '@/functions/top/views/css/slick.css';
</style>
